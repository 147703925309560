@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600;700&display=swap');

::-webkit-scrollbar-track {
    background-color: #eeee;
}

::-webkit-scrollbar {
    width: 15px;
    background-color: #f5f5f5;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(148, 148, 148, 0.883);
    border-radius: 100px;
}
